import Button from 'src/components/ui/Button'
import Link from 'src/components/ui/Link'

import type { StoresInformation } from '../types/index'
import { formatPhoneNumber } from '../FormatPhoneNumber'
import '../styles.scss'

export const Stores = ({
  name,
  address,
  phone,
  pageLink,
}: StoresInformation) => {
  return (
    <>
      <section className="font-roboto pr-10 max-w-[265px] sm:pr-0 sm:max-w-[312px] sm:w-full vs:pr-0 vs:pb-0 vs-pr-0 vs:max-w-[312px] vs:w-full">
        <Button className="flex items-start h-[180px] xl:max-w-[265px] shadow-[0_6px_6px_0_rgba(0,83,125,0.1)] mr-6 mb-6 !p-0 vs:mr-0 sm:max-w-[312px] sm:w-full vs:max-w-[312px] rounded-lg w-[100vw] vs:w-full">
          <Link
            href={pageLink}
            target="_blank"
            className="!p-4 hover:!no-underline vs:mx-auto vs:my-0 vs:flex vs:justify-center vs:flex-col vs:h-full pp:h-[auto]"
          >
            <h1 className="uppercase text-xl font-bold text-[#001018] text-left m-0">
              {name}
            </h1>
            <p className="text-sm font-normal text-[#4E5D6B] text-left mt-1 mb-2">
              {address}
            </p>
            <p className="text-sm font-normal text-[#4E5D6B] text-left">
              {formatPhoneNumber(phone)}
            </p>
          </Link>
        </Button>
      </section>
    </>
  )
}
