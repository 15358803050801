export const formatPhoneNumber = (str: string | undefined) => {
  const change = `${str}`.replace(/\D/g, '')
  const matchNumber = change.match(/^(\d{2})(\d{4})(\d{4})$/)
  const matchWhats = change.match(/^(\d{2})(\d{5})(\d{4})$/)

  if (matchNumber) {
    return `(${matchNumber[1]}) ${matchNumber[2]}-${matchNumber[3]}`
  }

  if (matchWhats) {
    return `(${matchWhats[1]}) ${matchWhats[2]}-${matchWhats[3]}`
  }

  return null
}
