import axios from 'axios'
import { useEffect, useState } from 'react'

import type { StoresInformation, StoresProps } from './types/index'
import { Stores } from './InputStores/StoresInfo'

const OurStores = () => {
  const [selectedStores, setSelectedStores] = useState<StoresInformation[]>()
  const [allStores, setAllStores] = useState<StoresProps[]>()
  const [stateOptions, setStateOptions] = useState<any[]>()

  useEffect(() => {
    if (!allStores) {
      return
    }

    const getStoresByState = (stateId: string) => {
      return allStores
        ?.filter((store) => store.state === stateId)
        .map((store) => store.store_id)
    }

    const getStates = async () => {
      const { data } = await axios.get(
        `https://decathlonstore.myvtex.com/api/dataentities/ST/search?_fields=id,abbreviation,name`,
        {
          headers: {
            Accept: 'application/json',
            'REST-range': 'resources=0-5000',
            'Content-Type': 'application/json; charset=utf-8',
          },
        }
      )

      const sortedStates = data.sort((a: StoresProps, b: StoresProps) =>
        a?.name > b?.name ? 1 : b?.name > a?.name ? -1 : 0
      )

      const allStore = {
        id_state: 0,
        initials: 'AS',
        name: 'Brasil - Todos os estados',
        code: 0,
        store_ids: ['0'],
      }

      const states = sortedStates
        .map((state: any) => ({
          initials: state.abbreviation,
          name: state.name,
          store_ids: getStoresByState(state.id),
        }))
        .filter((state: any) => state.store_ids.length > 0)

      setStateOptions([allStore, ...states])
    }

    getStates()
  }, [allStores])

  useEffect(() => {
    const getStores = async () => {
      const { data } = await axios.get(
        `https://decathlonstore.myvtex.com/api/dataentities/SL/search?_&_fields=state,store_id,address,openingHours,name,services_ids,embed_link,address_complement,phone,specialMonth,specialHolidaySchedule,googleLink,pageLink,whatsapp,instagram,facebook&_where=status_active=true`,
        {
          headers: {
            Accept: 'application/json',
            'REST-range': 'resources=0-5000',
            'Content-Type': 'application/json; charset=utf-8',
          },
        }
      )

      const auxStores = data.sort((a: StoresProps, b: StoresProps) =>
        a?.name > b?.name ? 1 : b?.name > a?.name ? -1 : 0
      )

      setAllStores(auxStores)
      setSelectedStores(auxStores)
    }

    getStores()
  }, [])

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const storesIds = e.target.value.split(',')

    if (storesIds.includes('0')) {
      setSelectedStores(allStores)

      return
    }

    const getId = allStores?.reduce((a: any, obj: StoresProps) => {
      a[obj.store_id] = obj

      return a
    }, {})

    setSelectedStores(storesIds.map((id: any) => getId[id]))
  }

  const selectedStoreOrder = selectedStores?.sort(
    (a: StoresInformation, b: StoresInformation) =>
      a?.name > b?.name ? 1 : b?.name > a?.name ? -1 : 0
  )

  return (
    <>
      <div className="our-stores_container font-roboto">
        <div className="bg-[#00689D] min-h-[223px] flex justify-end flex-col pt-0 pb-4 pr-6 pl-[104px] vs:pl-10">
          <h1 className="text-[42px] font-bold leading-[44px] italic uppercase text-white">
            Explore
          </h1>
          <p className="text-base font-bold text-white">
            Encontre uma Decathlon perto de você
          </p>
        </div>
        <div className="our-stores_content bg-white">
          {/* Option Stores */}
          <section className="input-stores_container font-roboto px-6 py-6 flex xl:flex-row xl:items-start sm:flex-col sm:items-center vs:flex-col vs:items-center mx-20 vs:mx-0">
            <div className="flex flex-col max-w-[312px] w-full">
              <p className="text-base font-bold text-[#001018] pb-1 vs:pb-4">
                Selecionar da lista
              </p>
              <label>
                <select
                  className="text-base font-normal text-[#001018] max-w-[312px] vs:w-auto h-12 rounded outline-none py-3 px-3 border-[1px] border-solid border-[#0082C3] cursor-pointer w-full bg-white"
                  id="stores"
                  onChange={(e) => handleChange(e)}
                >
                  {stateOptions?.map((item, index: number) => {
                    return (
                      <option
                        key={index}
                        className="text-base font-normal text-[#001018] bg-white cursor-pointer"
                        value={item.store_ids}
                      >
                        {item?.name ?? ''}
                      </option>
                    )
                  })}
                </select>
              </label>
            </div>
            <div>
              {selectedStoreOrder?.length === 1 ? (
                <p className="text-sm font-normal text-[#687787] px-6 lg:pt-0 sm:pt-4 sm:max-w-[312px] sm:flex sm:justify-start sm:mx-auto sm:my-0 sm:pl-0 md:pt-4 vs:pt-4 vs:max-w-[312px] vs:flex vs:justify-start vs:mx-auto vs:my-0 vs:pl-0 xl:mx-0 xl:my-0 xl:pl-6">
                  {`0${selectedStoreOrder?.length} resultado encontrado`}
                </p>
              ) : (
                <p className="text-sm font-normal text-[#687787] px-6 lg:pt-0 sm:pt-4 md:pt-4 sm:max-w-[312px] sm:flex sm:justify-start sm:mx-auto sm:my-0 sm:pl-0 vs:pt-4 vs:max-w-[312px] vs:flex vs:justify-start vs:mx-auto vs:my-0 vs:pl-0 xl:mx-0 xl:my-0 xl:pl-6 xl:mb-[3px] xl:mt-[3px]">
                  {selectedStoreOrder?.length && selectedStoreOrder.length <= 9
                    ? `0${selectedStoreOrder?.length} resultados encontrados`
                    : `${selectedStoreOrder?.length} resultados encontrados`}
                </p>
              )}

              <div className="flex flex-wrap max-w-[954px] w-full xl:justify-start sm:justify-center vs:justify-center vs:items-center px-6 lg:pt-0  md:pt-4 vs:pt-4 vs:px-0">
                {selectedStoreOrder &&
                  selectedStoreOrder?.map(
                    (item: StoresInformation, indexStores: number) => {
                      return (
                        <div
                          key={indexStores}
                          className="w-auto vs:w-full vs:flex vs:justify-center"
                          // className="w-auto justify-start sm:w-full sm:flex sm:justify-center vs:w-full vs:flex vs:justify-center"
                        >
                          <Stores
                            name={item?.name ?? ''}
                            address={item?.address ?? ''}
                            phone={item?.phone ?? ''}
                            pageLink={item?.pageLink ?? ''}
                          />
                        </div>
                      )
                    }
                  )}
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  )
}

export default OurStores
